import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="prestige-classes"></a>
    <h2>
Prestige Classes
    </h2><a id="archmage"></a>
    <h3>
ARCHMAGE
    </h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <p className="initial">
      <a href="http://www.wizards.com/dnd/images/dmg35_gallery/DMG35_PG179_WEB.jpg">
        <img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><b>Hit
Die</b>: d4.
    </p><a id="archmage-requirements"></a>
    <h6>
Requirements
    </h6>
    <p className="initial">
      <p>{`To qualify to become an archmage, a character must fulfill all the
following criteria.`}</p>
    </p>
    <p>
      <b>Skills</b>: Knowledge (arcana) 15 ranks, Spellcraft 15 ranks.
    </p>
    <p>
      <b>Feats</b>: Skill Focus (Spellcraft), Spell Focus in two schools
of magic.
    </p>
    <p>
      <b>Spells</b>: Ability to cast 7th-level arcane spells, knowledge
of 5th-level or higher spells from at least five schools.
    </p>
    <h6>
Class Skills
    </h6>
    <p className="initial">
The archmage's class skills (and the key ability for each skill)
are <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#concentration">Concentration</a> (Con), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#craft">Craft</a>
      <p>{`(alchemy) (Int), `}<a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#knowledge">{`Knowledge`}</a>{` (all skills taken
individually) (Int), `}<a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#profession">{`Profession`}</a>{` (Wis), `}<a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#search">{`Search`}</a>{` (Int), and `}<a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#spellcraft">{`Spellcraft`}</a>{` (Int).`}</p>
    </p>
    <p>
      <b>Skill Points at Each Level</b>: 2 + Int modifier.
    </p><a id="table-the-archmage"></a>
    <p>
      <b>Table: The Archmage</b>
    </p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "7%"
          }}>
Level
          </th>
          <th style={{
            "width": "12%"
          }}>
Base
            <p>{`Attack Bonus`}</p>
          </th>
          <th style={{
            "width": "7%"
          }}>
Fort
            <p>{`Save`}</p>
          </th>
          <th style={{
            "width": "7%"
          }}>
Ref
            <p>{`Save`}</p>
          </th>
          <th style={{
            "width": "7%"
          }}>
Will
            <p>{`Save`}</p>
          </th>
          <th style={{
            "width": "16%"
          }}>
Special
          </th>
          <th style={{
            "width": "44%"
          }}>
Spells per Day
          </th>
        </tr>
        <tr className="odd-row">
          <td>
1st
          </td>
          <td>
+0
          </td>
          <td>
+0
          </td>
          <td>
+0
          </td>
          <td>
            <p>{`+2`}</p>
          </td>
          <td>
High arcana
          </td>
          <td>
+1 level of existing arcane spellcasting class
          </td>
        </tr>
        <tr>
          <td>
2nd
          </td>
          <td>
+1
          </td>
          <td>
+0
          </td>
          <td>
            <p>{`+0`}</p>
          </td>
          <td>
+3
          </td>
          <td>
High arcana
          </td>
          <td>
+1 level of existing arcane spellcasting class
          </td>
        </tr>
        <tr className="odd-row">
          <td>
3rd
          </td>
          <td>
+1
          </td>
          <td>
+1
          </td>
          <td>
+1
          </td>
          <td>
+3
          </td>
          <td>
High arcana
          </td>
          <td>
            <p>{`+1 level of existing arcane spellcasting class`}</p>
          </td>
        </tr>
        <tr>
          <td>
4th
          </td>
          <td>
+2
          </td>
          <td>
+1
          </td>
          <td>
+1
          </td>
          <td>
+4
          </td>
          <td>
            <p>{`High arcana`}</p>
          </td>
          <td>
+1 level of existing arcane spellcasting class
          </td>
        </tr>
        <tr className="odd-row">
          <td>
5th
          </td>
          <td>
+2
          </td>
          <td>
+1
          </td>
          <td>
+1
          </td>
          <td>
            <p>{`+4`}</p>
          </td>
          <td>
High arcana
          </td>
          <td>
+1 level of existing arcane spellcasting class
          </td>
        </tr>
      </tbody>
    </table>
    <a id="archmage-class-features"></a>
    <h6>
Class Features
    </h6>
    <p className="initial">
All the following are Class Features of the archmage prestige
class.
    </p>
    <p>
      <b>Weapon and Armor Proficiency</b>: Archmages gain no proficiency
with any weapon or armor.
    </p>
    <p>
      <b>Spells per Day/Spells Known</b>: When a new archmage level is
gained, the character gains new spells per day (and spells known,
if applicable) as if he had also gained a level in whatever arcane
spellcasting class in which he could cast 7th-level spells before
he added the prestige class level. He does not, however, gain any
other benefit a character of that class would have gained. If a
character had more than one arcane spellcasting class in which he
could cast 7th-level spells before he became an archmage, he must
decide to which class he adds each level of archmage for the
purpose of determining spells per day.
    </p><a id="archmage-high-arcana"></a>
    <p>
      <b>High Arcana</b>: An archmage gains the opportunity to select a
special ability from among those described below by permanently
eliminating one existing spell slot (she cannot eliminate a spell
slot of higher level than the highest-level spell she can cast).
Each special ability has a minimum required spell slot level, as
specified in its description.
    </p>
    <p>
An archmage may choose to eliminate a spell slot of a higher level
than that required to gain a type of high arcana.
    </p><a id="archmage-arcane-fire"></a>
    <p>
      <i>Arcane Fire (Su)</i>: The archmage gains the ability to change
arcane spell energy into arcane fire, manifesting it as a bolt of
raw magical energy. The bolt is a ranged touch attack with long
range (400 feet + 40 feet/level of archmage) that deals 1d6 points
of damage per class level of the archmage plus 1d6 points of
damage per level of the spell used to create the effect. This
ability costs one 9th-level spell slot.
    </p><a id="archmage-arcane-reach"></a>
    <p>
      <i>Arcane Reach (Su)</i>: The archmage can use spells with a range
of touch on a target up to 30 feet away. The archmage must make a
ranged touch attack. Arcane reach can be selected a second time as
a special ability, in which case the range increases to 60 feet.
This ability costs one 7th-level spell slot.
    </p><a id="archmage-mastery-of-counterspelling"></a>
    <p>
      <i>Mastery of Counterspelling</i>: When the archmage counterspells
a spell, it is turned back upon the caster as if it were fully
affected by a spell turning spell. If the spell cannot be affected
by spell turning, then it is merely counterspelled. This ability
costs one 7th-level spell slot.
    </p><a id="archmage-mastery-of-elements"></a>
    <p>
      <i>Mastery of Elements</i>: The archmage can alter an arcane spell
when cast so that it utilizes a different element from the one it
normally uses. This ability can only alter a spell with the acid,
cold, fire, electricity, or sonic descriptor. The spell's casting
time is unaffected. The caster decides whether to alter the
spell&#8217;s energy type and chooses the new energy type when he begins
casting. This ability costs one 8th-level spell slot.
    </p><a id="archmage-mastery-of-shaping"></a>
    <p>
      <i>Mastery of Shaping</i>: The archmage can alter area and effect
spells that use one of the following shapes: burst, cone,
cylinder, emanation, or spread. The alteration consists of
creating spaces within the spell's area or effect that are not
subject to the spell. The minimum dimension for these spaces is a
5-foot cube. Furthermore, any shapeable spells have a minimum
dimension of 5 feet instead of 10 feet. This ability costs one
6th-level spell slot.
    </p><a id="archmage-spell-power"></a>
    <p>
      <i>Spell Power</i>: This ability increases the archmage's
effective caster level by +1 (for purposes of determining
level-dependent spell variables such as damage dice or range, and
caster level checks only). This ability costs one 5th-level spell
slot.
    </p><a id="archmage-spell-like-ability"></a>
    <p>
      <i>Spell-Like Ability</i>: An archmage who selects this type of
high arcana can use one of her arcane spell slots (other than a
slot expended to learn this or any other type of high arcana) to
permanently prepare one of her arcane spells as a spell-like
ability that can be used twice per day. The archmage does not use
any components when casting the spell, although a spell that costs
XP to cast still does so and a spell with a costly material
component instead costs her 10 times that amount in XP. This
ability costs one 5th-level spell slot.
    </p>
    <p>
The spell-like ability normally uses a spell slot of the spell's
level, although the archmage can choose to make a spell modified
by a metamagic feat into a spell-like ability at the appropriate
spell level.
    </p>
    <p>
The archmage may use an available higher-level spell slot in order
to use the spell-like ability more often. Using a slot three
levels higher than the chosen spell allows her to use the
spell-like ability four times per day, and a slot six levels
higher lets her use it six times per day.
    </p>
    <p>
      <p>{`If spell-like ability is selected more than one time as a high
arcana choice, this ability can apply to the same spell chosen the
first time (increasing the number of times per day it can be used)
or to a different spell.`}</p>
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      